@import "../../common.scss";

.chapter-left {
	width: responsiveSizeLegacyToV2(313.67);
	height: responsiveSizeLegacyToV2(398.58);
	display: flex;
	flex-direction: column;    
	justify-content: center;
	align-items: center;
	flex: 1;
	&__poster {
		position: relative;
		&, img {
			width: 100%;
			height: responsiveSizeLegacyToV2(322.95);
			border-radius: responsiveSizeLegacyToV2(18);
		}
	}
	&__infos {
		display: flex;
		font-family: "Avenir";
		word-break: break-all;
		margin-top: responsiveSizeLegacyToV2(20);
		&-thumbnail {
			&, svg {
				width: responsiveSizeLegacyToV2(56);
				height: responsiveSizeLegacyToV2(56);
			}
			margin-right: responsiveSizeLegacyToV2(6.3);
		}
		h2 {
			color: white;
			font-size: responsiveSizeLegacyToV2(17);
			text-transform: uppercase;
			margin-bottom: responsiveSizeLegacyToV2(3.4);
		}
		span {
			margin-left: responsiveSizeLegacyToV2(9);
		}
	}
}
