@import "../../common.scss";

.video-left-area {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: responsiveSizeLegacyToV2(281);
	margin-top: responsiveSizeLegacyToV2(30);
	height: auto;
	color: white;
	font-family: "Avenir";
	&__thumbnails {
		width: 100%;
		position: relative;
		min-height: responsiveSizeLegacyToV2(299.25);
		img {
			width: 100%;
			height: auto;
			border-radius: responsiveSizeLegacyToV2(5);
		}
	}
	p {
		text-align: center;
		margin-top: responsiveSizeLegacyToV2(7);
		margin-bottom: responsiveSizeLegacyToV2(22);
		font-size: responsiveSizeLegacyToV2(24);
		font-weight: bolder;
	}
}

.ff-video-left-area-autoplay {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: responsiveSizeLegacyToV2(20);
	margin-bottom: responsiveSizeLegacyToV2(30);
	&-toggle {
		display: flex;
		align-items: center;
		position: relative;
		width: responsiveSizeLegacyToV2(80);
		height: responsiveSizeLegacyToV2(50);
		&-background {
			width: auto;
			height: 100%;
			top: 0;
			left: 0;
			position: absolute;
		}
		&-circle {
			width: auto;
			height: responsiveSizeLegacyToV2(32);
			z-index: 1;
			margin-left: responsiveSizeLegacyToV2(9.5);
			transition: 0.1s transform ease-out;
		}
		&.off &-background #Rectangle_618-2 {
			fill: #C3C3C3;
		}

		&.off &-circle {
			transform: translate(0%);
		}

		&.on &-circle {
			transform: translate(responsiveSizeLegacyToV2(35.5));
		}
	}
	&-text {
		color: white;
		font-weight: 900;
		font-family: 'Avenir';
		font-size: responsiveSizeLegacyToV2(19);
		margin-left: responsiveSizeLegacyToV2(13);
	}
}

.ff-video-left-area-buttons {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	width: responsiveSizeLegacyToV2(281);
	height: auto;
	color: white;
	font-family: "Fredoka One";
	font-size: responsiveSizeLegacyToV2(24);
	text-transform: uppercase;

	a {
		color: white;
		text-decoration: none;
	}
	&__btn {
		display: flex;
		align-items: center;
		width: 100%;
		height: responsiveSizeLegacyToV2(62);
		border-radius: responsiveSizeLegacyToV2(42);
		border-width: responsiveSizeLegacyToV2(3);
		border: solid white;
		cursor: pointer;
		margin-bottom: responsiveSizeLegacyToV2(26);
		&:last-child {
			margin-bottom: 0;
		}
		svg {
			cursor: pointer;
			margin-right: responsiveSizeLegacyToV2(15.5);
			margin-left: responsiveSizeLegacyToV2(28);
		}
		.icon-home {
			width: responsiveSizeLegacyToV2(34);
			height: responsiveSizeLegacyToV2(34);
		}
		.icon-chapters {
			width: responsiveSizeLegacyToV2(36);
			height: responsiveSizeLegacyToV2(36);
		}
		.icon-fullscreen {
			width: responsiveSizeLegacyToV2(36.48);
			height: responsiveSizeLegacyToV2(36.48);
		}
	}
}
