@import '../../common.scss';

.hp-layoutV2 {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  height: var(--mainContentHeight);
  max-width: var(--vw);
  position: relative;
  @at-root #famillefoulane {
    height: var(--vmin);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .hp-topBanner {
    display: none;
  }
  .hp-clipLoader {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    span {
      position: relative !important;
    }
  }
  .hp-headerArea {
    top: 0;
    left: 0;
    position: absolute;
    height: var(--headerHeight);
    width: var(--leftAreaMinWidth);
    display: flex;
    justify-content: center;
  }
  .hp-leftArea {
    flex: 1;
    min-width: var(--leftAreaMinWidth);
    height: calc(var(--mainContentHeight) - var(--headerHeight));
    margin-top: var(--headerHeight);
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    position: relative;
  }
  .ks-content {
    margin-right: 0;
    background: linear-gradient(90deg, rgba(24, 56, 93, 1) 0%, rgba(53, 42, 83, 1) 100%);
    border-radius: responsiveSizeLegacyToV2(18);
    width: var(--mainContentWidth);
    height: var(--mainContentHeight);
    overflow: hidden;
    position: relative;
  }
  &.walad {
    .ks-content {
      background: linear-gradient(0deg, #AA8C5D 0%, #70C2D3 100%);
    }
  }
  &.fullscreen {
    .hp-leftArea,
    .hp-headerArea {
      display: none;
    }
  }
}

.portrait {
  .hp-layoutV2 {
    flex-direction: column-reverse;
    height: var(--vmin);
  }
  .hp-leftArea {
    width: 100%;
    margin-top: 0;
    display: block;
  }
  .ks-content {
    margin-left: 0;
    margin-right: 0;
    margin-top: var(--headerHeight);
  }
}