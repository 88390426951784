@import "../../common.scss";

.ff-home-icon {
	flex: 1;
	bottom: responsiveSizeLegacyToV2(601);
	width: responsiveSizeLegacyToV2(319.39);
	height: responsiveSizeLegacyToV2(206.36);
	display: flex;
	svg {
		width: 100%;
		height: 100%;
	}
}

.hp-menu-icon {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	width: responsiveSizeLegacyToV2(324.95);
	height: fit-content;
	&--link {
		cursor: pointer;
		width: responsiveSizeLegacyToV2(96.39);
		height: responsiveSizeLegacyToV2(96.39);
		margin-bottom: responsiveSizeLegacyToV2(15);
		svg {
			cursor: pointer;
			width: 100%;
			height: 100%;
		}
	}
}
