@import "../../common.scss";

.ff-video-player {
	width: 100%;
	height: 100%;
	border: none;
	background-color: #000000;
	&-name {
		display: none;
	}
	iframe {
		width: 100%;
		height: 100%;
	}
}
.ff-video-player-btn {
	z-index: 1;
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: center;
	background-color: rgba($color: #000000, $alpha: 0.9);
	svg {
		width: responsiveSizeLegacyToV2(334);
		height: responsiveSizeLegacyToV2(99);
		&:not(:last-child) {
			margin-bottom: responsiveSizeLegacyToV2(33);
		}
	}
}

.ff-iconFullScreen {
	position: absolute;
	width: responsiveSizeLegacyToV2(80);
	height: responsiveSizeLegacyToV2(80);
	cursor: pointer;
}

// ******** Responsive Mode ********

.portrait {
	.ff-video-player {
		position: relative;
		width: 100% !important;
		height: responsiveSizeLegacyToV2(850) !important;
		border: none;
		&-name {
			position: relative;
			display: block;
			margin-top: responsiveSizeLegacyToV2(207.9);
			margin-bottom: responsiveSizeLegacyToV2(20.2);
			text-align: center;
			font-family: "Avenir";
			font-size: responsiveSizeLegacyToV2(39);
			color: white;
		}
		&-background {
			position: absolute;
			width: 100%;
			height: 100%;
			transform: scale(1.1);
			background-color: black;
			z-index: 0;
		}
	}
}
