@import "../../common.scss";

.chapter-list__chapter.chapter-list__chapter-blocked .chapter-list__chapter__img-wrapper {
	.overlay {
	 display: none;
 }
}

.chapter-buttons {
	display: flex;
	color: white;
	align-items: center;
	margin-top: responsiveSizeLegacyToV2(50);
	margin-bottom: responsiveSizeLegacyToV2(50);
	&__full-movie,
	&__back-home {
		text-decoration: none;
		color: white;
		cursor: pointer;
		display: flex;
		align-items: center;
		justify-content: center;
		text-transform: uppercase;
		font-family: "Fredoka One";
		font-size: responsiveSizeLegacyToV2(24);
		border-width: responsiveSizeLegacyToV2(3);
		border: solid white;
		border-radius: responsiveSizeLegacyToV2(42);
		height: responsiveSizeLegacyToV2(70);
	}
	&__full-movie {
		width: responsiveSizeLegacyToV2(452);
		margin-right: responsiveSizeLegacyToV2(22);
		.full-movie-icon {
			width: responsiveSizeLegacyToV2(40.18);
			height: responsiveSizeLegacyToV2(40.18);
			margin-right: responsiveSizeLegacyToV2(14);
		}
		&:hover {
			background-color: #8c86c1;
		}
	}
	&__back-home {
		width: responsiveSizeLegacyToV2(198);
		background-color: black;
		.back-home-icon {
			width: responsiveSizeLegacyToV2(31.9);
			height: responsiveSizeLegacyToV2(31.9);
			margin-right: responsiveSizeLegacyToV2(14);
		}
	}
}

.chapter-list {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	width: 100%;
	height: 100%;
	max-height: responsiveSizeLegacyToV2(1004);
	margin: auto;
	margin-top: responsiveSizeLegacyToV2(17);
	overflow-y: scroll;
	overflow-x: hidden;
	padding: 0 responsiveSizeLegacyToV2(17);
	box-sizing: border-box;
	&::-webkit-scrollbar {
		-ms-overflow-style: none;
		scrollbar-width: none;
		display: none;
	}
	&__chapter {
		display: flex;
		width: responsiveSizeLegacyToV2(669);
		color: white;
		text-decoration: none;
		font-family: "Avenir";
		margin-bottom: responsiveSizeLegacyToV2(10);
		position: relative;
		&.soon {
			opacity: 0.4;
			pointer-events: none;
			cursor: not-allowed;
			.chapter-list__infos {
				font-weight: 700;
			}
			.chapter-list__play-time,
			.play-mobile {
				display: none;
			}
		}
		.overlay {
			display: none;
		}
		&.chapter-list__chapter-blocked {
			.chapter-list__play-time {
				.lock {
					width: responsiveSizeLegacyToV2(40);
					height: responsiveSizeLegacyToV2(45);
					margin-right: responsiveSizeLegacyToV2(30);
				}
			}
			.overlay {
				display: block;
				position: absolute;
				z-index: 2;
				top: 0;
				left: 0;
				width: responsiveSizeLegacyToV2(669);
				height: responsiveSizeLegacyToV2(315);
				border-radius: responsiveSizeLegacyToV2(5);
				background-color: rgba($color: #7191C6, $alpha: 0.7);
				display: flex;
				justify-content: center;
				align-items: center;
				svg {
					position: absolute;
					width: responsiveSizeV2(44);
					height: responsiveSizeV2(51);
					#Icon_awesome-lock {
						fill: #D6E2F5;
					}
				}
			}
		}
		&__img-wrapper {
			position: relative;
			&, &--thumbnails {
				width: responsiveSizeLegacyToV2(296);
				height: responsiveSizeLegacyToV2(315);
			}
			&--thumbnails {
				border-radius: responsiveSizeLegacyToV2(5);
			}
		}
	}
	&__infos {
		position: relative;
		width: responsiveSizeLegacyToV2(370);
		height: responsiveSizeLegacyToV2(315);
		margin: 0 auto;
		padding-top: responsiveSizeLegacyToV2(11);
		margin-left: responsiveSizeLegacyToV2(3);
		padding-right: responsiveSizeLegacyToV2(22);
		padding-left: responsiveSizeLegacyToV2(29);
		background-color: rgba($color: #000000, $alpha: 0.4);
		border-radius: responsiveSizeLegacyToV2(5);
		box-sizing: border-box;
		h2 {
			font-size: responsiveSizeLegacyToV2(24);
			margin-bottom: responsiveSizeLegacyToV2(6.4);
			font-weight: 900;
		}
		p {
			font-size: responsiveSizeLegacyToV2(19);
			color: #959fa8;
			overflow: hidden;
			height: 100%;
			max-height: responsiveSizeLegacyToV2(162.99);
			line-height: 1.2;
			&::-webkit-scrollbar {
				-ms-overflow-style: none;
				scrollbar-width: none;
				display: none;
			}
		}
	}
	&__play-time {
		position: absolute;
		display: flex;
		align-items: flex-end;
		right: responsiveSizeLegacyToV2(22);
		bottom: responsiveSizeLegacyToV2(20);
		.play-chapter {
			width: responsiveSizeLegacyToV2(64.78);
			height: responsiveSizeLegacyToV2(64.78);
			margin-right: responsiveSizeLegacyToV2(73);
		}
		span {
			height: 100%;
		}
	}
}

.walad {
	.chapter-list {
		&__infos {
			color: #484848;
			background-color: white;
			h2 {
				color: black;
			}
		}
		.soon {
			opacity: 1;
			.chapter-list__infos {
				opacity: 0.7;
				background-color: #000000;
				h2 {display: none;}
				.soonTitle {
					height: 92%;
					width: 100%;
					display: flex;
					align-items: center;
					justify-content: center;	
					font-family: 'Avenir';
					font-weight: 900;
					font-size: responsiveSizeToV2(20);
				}
			}
		}
		&__play-time {
			.time {
				color: white;
				background-color: #87ADA4;
			}
			.play-chapter path {
				fill: #87ADA4;
			}
		}
	}
}

.play-mobile {
	display: none;
}

// ******* Responsive Mode *******

.portrait {
	.chapter-buttons {
		margin-top: responsiveSizeLegacyToV2(79);
		width: responsiveSizeLegacyToV2(955.63);
		margin-left: auto;
		margin-right: auto;
		justify-content: space-between;
		&__full-movie,
		&__back-home {
			height: responsiveSizeLegacyToV2(101);
			border-radius: responsiveSizeLegacyToV2(59);
			font-size: responsiveSizeLegacyToV2(34);
		}
		&__full-movie {
			width: responsiveSizeLegacyToV2(586);
			background-color: white;
			color: #27477a;
			margin: 0;
			.full-movie-icon {
				width: responsiveSizeLegacyToV2(66.9);
				height: responsiveSizeLegacyToV2(66.9);
				margin-right: responsiveSizeLegacyToV2(17.7);
			}
			&:hover {
				all: initial;
			}
		}
		&__back-home {
			width: responsiveSizeLegacyToV2(341.2);
			background-color: transparent;
			.back-home-icon {
				width: responsiveSizeLegacyToV2(58.01);
				height: responsiveSizeLegacyToV2(58.01);
				margin-right: responsiveSizeLegacyToV2(17.7);
			}
		}
	}

	.chapter-list {
		position: relative;
		flex-direction: column;
		width: 98%;
		flex-wrap: nowrap;
		max-height: 90%;
		margin-top: responsiveSizeLegacyToV2(80);
		&__chapter {
			width: 100%;
			height: responsiveSizeLegacyToV2(454.43);
			&--thumbnails {
				position: relative;
				width: responsiveSizeLegacyToV2(426.61);
				height: responsiveSizeLegacyToV2(454.43);
			}
		}
		&__infos {
			width: responsiveSizeLegacyToV2(533.74);
			height: responsiveSizeLegacyToV2(454.43);
			margin: 0;
			margin-left: responsiveSizeLegacyToV2(41.5);
			margin-top: responsiveSizeLegacyToV2(14);
			h2 {
				font-size: responsiveSizeLegacyToV2(39);
			}
			p {
				color: white;
				font-size: responsiveSizeLegacyToV2(35);
				max-height: responsiveSizeLegacyToV2(280);
			}
		}
		&__play-time {
			left: 0;
			right: initial;
			bottom: responsiveSizeLegacyToV2(31.9);
			.play-chapter {
				display: none;
			}
			span {
				width: responsiveSizeLegacyToV2(185.04);
				height: responsiveSizeLegacyToV2(61.68);
				font-size: responsiveSizeLegacyToV2(33);
				display: flex;
				align-items: center;
				justify-content: center;
			}
		}
	}

	.play-mobile {
		display: block;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		width: responsiveSizeLegacyToV2(124);
		height: responsiveSizeLegacyToV2(124);
	}
}

.lock {
	display: none;
}

.famille-foulane .chapter-list__chapter.chapter-list__chapter-blocked {
	display: flex;
	align-items: center;
	justify-content: center;
	.lock {
		z-index: 10;
		width: auto;
		path {fill: #DDDDDD;}
		display: block;
		position: absolute;
		height: responsiveSizeLegacyToV2(51);
	}
}

.walad .chapter-list__chapter.chapter-list__chapter-blocked {
	.chapter-list__chapter__img-wrapper {
		display: flex;
		justify-content: center;
		align-items: center;
		.overlay {
			display: block;
			background-color: rgba($color: #645534, $alpha: 0.8);
			width: responsiveSizeLegacyToV2(296);
	 	}
		 svg {
			width: auto;
			height: responsiveSizeLegacyToV2(51);
		 }
		.lock {
			z-index: 10;
			path {fill: #DDDDDD;}
			display: block;
			position: absolute;
		}
	}
	 .overlay {
		display: none;
	}
}