@import "../../common.scss";

.controller {
	z-index: 2;
	position: absolute;
	display: flex;
	align-items: center;
	border-radius: responsiveSizeLegacyToV2(50);
	height: responsiveSizeLegacyToV2(99);
	background-color: black;
	min-width: responsiveSizeLegacyToV2(281);
	&--left {
		border-width: responsiveSizeLegacyToV2(3);
		border: solid #dddddd;
		position: initial;
		justify-content: space-evenly;
		width: responsiveSizeLegacyToV2(281);
	}
	&--prev-btn,
	&--next-btn {
		fill: #51aabc;
		cursor: pointer;
		width: responsiveSizeLegacyToV2(55);
		height: responsiveSizeLegacyToV2(55);
	}
	&--next-btn {
		transform: rotate(180deg);
	}
	&--play-btn {
		cursor: pointer;
		color: #51aabc;
		width: responsiveSizeLegacyToV2(83.62);
		height: responsiveSizeLegacyToV2(83.62);
	}
	&--reduce-btn {
		cursor: pointer;
		width: responsiveSizeLegacyToV2(59.29);
		height: responsiveSizeLegacyToV2(59.29);
		path {
			fill: black;
		}
	}
	&--btn-disabled {
		fill: #dddddd;
		cursor: not-allowed;
		pointer-events: none;
	}
}

// *********** Mobile Version **********

.portrait {
	.controller {
		&--main-theme-icon {
			width: responsiveSizeLegacyToV2(120.99);
			height: responsiveSizeLegacyToV2(120.99);
		}
		&--prev-btn,
		&--next-btn {
			width: responsiveSizeLegacyToV2(106);
			height: responsiveSizeLegacyToV2(106);
		}
		&--play-btn {
			width: responsiveSizeLegacyToV2(120.87);
			height: responsiveSizeLegacyToV2(120.87);
		}
		&--separator {
			height: responsiveSizeLegacyToV2(119.8);
		}
		&--content-icon {
			width: responsiveSizeLegacyToV2(91.41);
			height: responsiveSizeLegacyToV2(119.8);
		}
		&__dark-container {
			&--logout-btn {
				width: responsiveSizeLegacyToV2(91.74);
				height: responsiveSizeLegacyToV2(91.74);
			}
		}
	}
}
