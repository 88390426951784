@import "../../common.scss";

p,
h2 {
	margin: 0;
}

.ff-homepage-nav-left,
.ff-homepage-nav-right {
	z-index: 10;
	position: absolute;
	top: responsiveSizeV2(477);
	left: responsiveSizeV2(13);
	&, svg {
		width: responsiveSizeV2(90);
		height: responsiveSizeV2(90);
	}
}

.ff-homepage-nav-right {
	left: inherit;
	right: responsiveSizeV2(13);
}

.ff-homepage {
	.ks-content {
		display: flex;
		align-items: center;
		.ff-homepage-list {
			position: relative;
			width: var(--mainContentWidth);
			height: responsiveSizeLegacyToV2(907.45);
			display: flex;
			overflow-x: scroll;
			&.ff-homepage-centered {
				overflow-x: none;
				justify-content: center;
			}
			&::-webkit-scrollbar {
				-ms-overflow-style: none;
				scrollbar-width: none;
				display: none;
			}
			&__movie {
				position: relative;
				flex-shrink: 0;
				width: responsiveSizeLegacyToV2(551.51);
				height: 100%;
				border-radius: responsiveSizeLegacyToV2(22);
				margin-right: responsiveSizeLegacyToV2(45.5);
				background-color: black;
				font-family: "Avenir";
				text-decoration: none;
				&__cover {
					position: relative;
					overflow: hidden;
					height: responsiveSizeLegacyToV2(439, 88);
					border-top-left-radius: responsiveSizeLegacyToV2(22);
					border-top-right-radius: responsiveSizeLegacyToV2(22);
					&--poster {
						width: 100%;
						height: 100%;
						transform: scale(1.1);
					}
				}

				&--infos {
					display: flex;
					margin-top: responsiveSizeLegacyToV2(21);
					margin-bottom: responsiveSizeLegacyToV2(28.8);
					margin-left: responsiveSizeLegacyToV2(30.4);
					&-thumbnail {
						position: relative;
						margin-right: responsiveSizeLegacyToV2(10);
						&, svg {
							width: responsiveSizeLegacyToV2(62);
							height: responsiveSizeLegacyToV2(62);
						}
					}
					h2 {
						color: white;
						font-size: responsiveSizeLegacyToV2(23);
						text-transform: uppercase;
						margin-bottom: responsiveSizeLegacyToV2(5);
					}
					span {
						margin-left: responsiveSizeLegacyToV2(9);
					}
				}
				&--desc {
					color: white;
					font-size: responsiveSizeLegacyToV2(18);
					width: responsiveSizeLegacyToV2(421.67);
					margin-left: responsiveSizeLegacyToV2(30.4);
					margin-bottom: responsiveSizeLegacyToV2(18.7);
					&::first-letter {
						text-transform: capitalize;
					}
					p { line-height: 1.2; }
				}
				&__authors {
					font-size: responsiveSizeLegacyToV2(18);
					color: #808080;
					border-left: responsiveSizeLegacyToV2(2);
					border-left: solid #808080;
					padding-left: responsiveSizeLegacyToV2(11.9);
					margin-left: responsiveSizeLegacyToV2(30.4);
				}
				&--soon {
					position: absolute;
					display: flex;
					flex-direction: column;
					align-items: center;
					margin-top: responsiveSizeLegacyToV2(101.9);
					left: 50%;
					transform: translate(-50%);
					font-family: "Avenir";
					h2 {
						color: white;
						font-size: responsiveSizeLegacyToV2(23);
						text-transform: uppercase;
					}
					span {
						width: responsiveSizeLegacyToV2(407.3);
						height: responsiveSizeLegacyToV2(63.64);
						border-radius: responsiveSizeLegacyToV2(13);
						margin-top: responsiveSizeLegacyToV2(10);
						background-color: white;
						color: black;
						font-size: responsiveSizeLegacyToV2(36);
						font-weight: bolder;
						text-transform: uppercase;
						display: flex;
						align-items: center;
						justify-content: center;
					}
				}
				&--icons {
					display: none;
				}
			}

			&__movie:nth-child(1) {
				margin-left: responsiveSizeLegacyToV2(64.7);
			}
		}
	}
}
.icon-play-movie {
	position: absolute;
	bottom: responsiveSizeLegacyToV2(12.9);
	left: responsiveSizeLegacyToV2(30.5);
	color: white;
	width: responsiveSizeLegacyToV2(65.92);
	height: responsiveSizeLegacyToV2(65.92);
}

.movie-soon {
	opacity: 0.5;
	cursor: not-allowed;
	pointer-events: none;
}

.walad {
	&.ff-homepage .ks-content {
		.ff-homepage-list { 
			&__movie {
				color: #484848;
				overflow: hidden;
				background-color: white;
				width: responsiveSizeToV2(471);
				&--infos {
					h2 {
						color: black;
					}
					.time {
						border: 1px solid black;
					}
					.time, .status {
						display: flex;
						justify-content: center;
						align-items: center;
					}
				}
				&--desc {
					color: #484848;
					height: responsiveSizeToV2(186);
				}
				&.movie-soon {
					background-color: transparent;
					.ff-homepage-list__movie__cover {
						opacity: 0.8;
						background-color: #47504F;
					}
					.ff-homepage-list__movie--soon {
						margin: 0;
						height: 100%;
						width: 100%;
						background-color: white;
						h2 { 
							color: #484848; 
							font-weight: 900;
							margin-top: responsiveSizeToV2(101);
							font-size: responsiveSizeToV2(23);
						}
						span {
							color: white;
							display: flex;
							font-weight: 900;
							align-items: center;
							justify-content: center;
							background-color: #A98E60;
							width: responsiveSizeToV2(407);
							height: responsiveSizeToV2(64);
							font-size: responsiveSizeToV2(36);
							border-radius: responsiveSizeToV2(13);
						}
					}
				}
			}
		}
	}
}

// ******** Responsive Mode *********

.portrait {
	.ff-homepage-list {
		width: 100%;
		height: 90%;
		&__movie {
			width: responsiveSizeLegacyToV2(827.86);
			&__cover {
				height: responsiveSizeLegacyToV2(752.34);
			}
			&--infos {
				margin-left: responsiveSizeLegacyToV2(53.4);
				&-thumbnail {
					width: responsiveSizeLegacyToV2(98.32);
					height: responsiveSizeLegacyToV2(98.32);
				}
				h2 {
					font-size: responsiveSizeLegacyToV2(39);
				}
				span {
					margin-left: responsiveSizeLegacyToV2(15.8);
				}
			}
			&--desc {
				font-size: responsiveSizeLegacyToV2(35);
				width: responsiveSizeLegacyToV2(740);
				margin-left: responsiveSizeLegacyToV2(53.4);
				overflow-y: scroll;
				overflow-x: hidden;
				height: 100%;
				max-height: responsiveSizeLegacyToV2(250);
				&::-webkit-scrollbar {
					-ms-overflow-style: none;
					scrollbar-width: none;
					display: none;
				}
			}
			&__authors {
				display: none;
			}
			&--icons {
				position: absolute;
				left: 50%;
				transform: translateX(-50%);
				display: flex;
				.icon-lecture,
				.icon-index {
					display: flex;
					align-items: center;
					justify-content: flex-end;
					flex-direction: column;
					font-family: "Avenir";
					font-size: responsiveSizeLegacyToV2(39);
					text-transform: uppercase;
					font-weight: bolder;
					text-decoration: none;
					color: white;
					height: responsiveSizeLegacyToV2(242.08);
					width: responsiveSizeLegacyToV2(174);
				}
				.icon-lecture {
					margin-right: responsiveSizeLegacyToV2(99.4);
					svg {
						width: responsiveSizeLegacyToV2(169.49);
						height: responsiveSizeLegacyToV2(169.49);
					}
				}
				.icon-index {
					svg {
						width: responsiveSizeLegacyToV2(170.26);
						height: responsiveSizeLegacyToV2(120.6);
					}
				}
			}
		}
	}
	.icon-play-movie {
		display: none;
	}
}
