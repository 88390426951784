@import '../../common.scss';

@function responsiveMenuSize($sizeInPixel) {
  @return calc(#{$sizeInPixel} * var(--headerHeight) / 85);
}

.hp-layoutV2 {
  overflow: hidden;
  .hp-menu-frame {
    position: absolute;
    width: calc(var(--leftAreaWidth) - 15%);
    margin-top: calc(var(--headerHeight) + 4%);
    height: 0;
    background-color: #FFFFFF;
    border-radius: responsiveMenuSize(34);
    transition: height 0.5s ease-in-out;
    z-index: 10;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    &.open {
      height: calc((var(--mainContentHeight) - var(--headerHeight)) * 0.95);
    }
    .hp-menu-frame-btn {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 90%;
      &:not(:last-child) {
        border-bottom: 1px solid #C3C3C3;
      }
      .mainBtn {
        text-decoration: none;
        color: #484848;
        font-size: responsiveMenuSize(18);
        font-family: 'Avenir';
        font-weight: 900;
        width: 100%;
        height: responsiveMenuSize(40);
        background-color: #DDDDDD;
        border-radius: responsiveMenuSize(56);
        display: flex;
        align-items: center;
        justify-content: center;
        line-height: 1;
        margin-top: responsiveMenuSize(26);
        &:last-child {
          margin-bottom: responsiveMenuSize(26);
        }
        &.btnSelected {
          color: white;
          background-color: #51AABC;
        }
      }
      div.mainBtn {
        text-transform: uppercase;
        background-color: #484848;
        color: white;
				&.logout {
					cursor: pointer;
					color: #484848;
					background-color: #DDDDDD;
				}
      }
      &.hp-menu-frame-contact {
        padding: responsiveMenuSize(36) 0;
        a.btnWithIcon {
          width: 100%;
          display: flex;
          align-items: center;
          text-decoration: none;
          color: #808080;
          font-size: responsiveMenuSize(17);
          font-family: 'Avenir';
          font-weight: 900;
          line-height: 1.2;
          span {
            margin-left: responsiveMenuSize(20);
          }
          &:not(:last-child) {
            margin-bottom: responsiveMenuSize(20);
          }
          svg {
            height: auto;
            width: responsiveMenuSize(73);
          }
          &.active {
            color: #978459;
            svg path {
              fill: #978459;
            }
          }
        }
      }
    }
  }
  .hp-menu-header {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    a{
      height: 73.8%;
      svg.hp-menu-logo {
        cursor: pointer;
        width: auto;
        height: 100%;
        margin-left: responsiveMenuSize(10);
      }
    }
    .hp-menu-nav-frame {
      height: responsiveMenuSize(65);
      width: responsiveMenuSize(65);
      background-color: white;
      margin: responsiveMenuSize(10);
      margin-left: 0;
      border-radius: responsiveMenuSize(18);
      display: flex;
      justify-content: center;
      align-items: center;
      .hp-menu-nav-icon {
        margin-top: 30%;
        width: 65%;
        height: 70%;
        position: relative;
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
        -webkit-transition: transform .35s ease-in-out;
        -moz-transition: transform .35ss ease-in-out;
        -o-transition: transform .35ss ease-in-out;
        transition: transform .35ss ease-in-out;
        cursor: pointer;
        span {
          display: block;
          position: absolute;
          height: 0px;
          border-top: 2px solid #51AABC;
          border-bottom: 1px solid #51AABC;
          @include media("<1280px") {
            border-top: 1px solid #51AABC;
            border-bottom: 0px solid #51AABC;
          }
          width: 100%;
          background-color: #51AABC;
          border-radius: responsiveMenuSize(5);
          opacity: 1;
          left: 0;
          -webkit-transform: rotate(0deg);
          -moz-transform: rotate(0deg);
          -o-transform: rotate(0deg);
          transform: rotate(0deg);
          -webkit-transition: transform .35ss ease-in-out;
          -moz-transition: transform .35s ease-in-out;
          -o-transition: transform .35s ease-in-out;
          transition: transform .35s ease-in-out;
        }
        span:nth-child(1) {
          top: 0;
        }

        span:nth-child(2), span:nth-child(3) {
          top: 26%;
        }

        span:nth-child(4) {
          top: 52%;
        }

        &.open span:nth-child(1) {
          top: 26%;
          width: 0%;
          left: 50%;
        }

        &.open span:nth-child(2) {
          -webkit-transform: rotate(45deg);
          -moz-transform: rotate(45deg);
          -o-transform: rotate(45deg);
          transform: rotate(45deg);
        }

        &.open span:nth-child(3) {
          -webkit-transform: rotate(-45deg);
          -moz-transform: rotate(-45deg);
          -o-transform: rotate(-45deg);
          transform: rotate(-45deg);
        }

        &.open span:nth-child(4) {
          top: 26%;
          width: 0%;
          left: 50%;
        }
      }
    }
  }
}

.portrait {
  .hp-layoutV2 {
    .hp-menu-frame {
      margin-top: 0;
      width: fit-content;
      padding: 0 responsiveMenuSize(20);
      height: calc((var(--vh) - var(--headerHeight)) * 0.95);
      margin: 0;
      border-bottom-left-radius: 0;
      border-top-left-radius: 0;
      transform: translate(-100%);
      transition: transform 0.5s ease-in-out;
      &.open {
        transform: translate(0%);
      }
      .hp-menu-frame-btn {
        width: 100%;
        margin: 0 responsiveMenuSize(20);
      }
    }
    .hp-menu-header {
      justify-content: center;
      svg.hp-menu-logo {
        margin-left: 0;
      }
      .hp-menu-nav-frame {
        position: absolute;
        left: 0;
        margin: 0;
        margin-left: responsiveMenuSize(10);
        background-color: black;
      }
    }
  }
}